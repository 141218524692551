import "./../css/main.scss";

// Or import only needed plugins
import {
  Tooltip as Tooltip,
  Toast as Toast,
  Popover as Popover,
} from "bootstrap";

import "./cookie.js";
